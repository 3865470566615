// These are used in trial apps

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

Array.prototype.difference = function (e) {
    return this.filter(function (i) {
        return e.indexOf(i) < 0;
    });
};